var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex justify-start",staticStyle:{"gap":"20px"}},[_c('v-text-field',{attrs:{"clearable":"","outlined":"","dense":"","placeholder":"Search name, email, or etc.","prepend-inner-icon":"mdi-magnify","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","max-width":"400px","offset-y":"","nudge-top":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.dateRangeText,"placeholder":"Filter by Date Range","outlined":"","dense":"","append-icon":"mdi-calendar","clearable":"","readonly":""},on:{"click:clear":function($event){_vm.dateRange = []}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"color":"primary","range":"","no-title":"","max":_vm.maxDate},on:{"input":_vm.onDateSelected},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),([_vm.$keys.SALES_HEAD, _vm.$keys.ACCOUNTS, _vm.$keys.SUPER_ADMIN].includes(_vm.user.user_type))?_c('v-select',{attrs:{"menu-props":{ bottom: true, offsetY: true },"append-icon":"mdi-chevron-down","clearable":"","items":_vm.executive_list,"item-text":"name","item-value":"id","placeholder":"Filter by Sales","outlined":"","dense":""},model:{value:(_vm.executive),callback:function ($$v) {_vm.executive=$$v},expression:"executive"}}):_vm._e(),_c('v-select',{attrs:{"menu-props":{ bottom: true, offsetY: true },"append-icon":"mdi-chevron-down","clearable":"","items":_vm.source_list,"item-text":"name","item-value":"id","placeholder":"Filter by Source","outlined":"","dense":""},model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}}),(
          ['follow_up', 'carry_forward', 'irrelevant'].includes(
            _vm.getActiveTab.key
          )
        )?_c('v-select',{attrs:{"menu-props":{ bottom: true, offsetY: true },"append-icon":"mdi-chevron-down","items":_vm.reason_list,"item-text":"title","item-value":"id","clearable":"","outlined":"","dense":"","placeholder":"Filter by Reason"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-end",staticStyle:{"gap":"20px"}},[_c('v-btn',{staticClass:"text-capitalize px-7",attrs:{"color":"primary","height":"40px","outlined":""},on:{"click":function($event){return _vm.LeadsModal({ show: true, id: null, type: 'add' })}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Add Lead")])],1)],1)]),_c('v-sheet',{staticClass:"rounded-b-0",attrs:{"elevation":"3"}},[_c('v-tabs',{attrs:{"fixed-tabs":"","height":"125px","next-icon":"mdi-chevron-right-circle","prev-icon":"mdi-chevron-left-circle","show-arrows":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabs),function(item,index){return _c('v-tab',{key:index + item.key,staticClass:"tab-width"},[_c('div',[_c('div',{staticClass:"tab-total"},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('div',{staticClass:"tab-name pt-2"},[_vm._v(" "+_vm._s(item.label)+" ")])])])}),1)],1),_c('v-divider'),_c('clientManagementTable',{ref:"dataTable",on:{"reloadData":_vm.getClientCount}}),_c('add-leads-modal',{on:{"submitted":_vm.reloadData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }