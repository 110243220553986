<template>
  <div>
    <v-data-table
      class="elevation-3 rounded-t-0"
      :items-per-page="page_count"
      :server-items-length="page_count"
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
      @dblclick:row="onItemDoubleClick"
    >
      <template v-slot:[`item.created`]="{ item }">
        {{ $utils.getDateFormat(item.created) }}
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div v-if="item.name">
          {{ item.name }}
        </div>
        <div v-else>
          <v-icon size="20">mdi-minus</v-icon>
        </div>
      </template>

      <template v-slot:[`item.follow_up_date`]="{ item }">
        <div v-if="item.follow_up">
          {{ $utils.getDateFormat(item.follow_up.follow_up_date) }}
        </div>
        <div v-else>
          <v-icon size="20">mdi-minus</v-icon>
        </div>
      </template>

      <template v-slot:[`item.follow_up_reason`]="{ item }">
        <div v-if="item.follow_up">
          {{ item.follow_up.reason }}
        </div>
        <div v-else>
          <v-icon size="20">mdi-minus</v-icon>
        </div>
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        <div v-if="item.user.mobile || item.user.email">
          <div v-if="item.user.mobile">
            {{ item.user.mobile_display }}
          </div>
          <div v-if="item.user.email">
            {{ item.user.email }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.referred_by`]="{ item }">
        <div class="py-4" v-if="item.referred_by">
          {{ item.referred_by.name }}
        </div>
        <div v-else>
          <v-icon size="20">mdi-minus</v-icon>
        </div>
      </template>

      <template v-slot:[`item.source`]="{ item }">
        <div v-if="item.source">
          <v-chip small color="primary" outlined>
            {{ item.source.name }}
          </v-chip>
        </div>
        <div v-else>
          <v-icon size="20">mdi-minus</v-icon>
        </div>
      </template>

      <template v-slot:[`item.remark`]="{ item }">
        <div v-if="item.remark">
          {{ item.remark }}
        </div>
        <div v-else>
          <v-icon size="20">mdi-minus</v-icon>
        </div>
      </template>

      <template v-slot:[`item.assigned_sales`]="{ item }">
        <div class="py-4">
          <div v-if="item.sales_executive">
            <div>
              {{ item.sales_executive.name }}
            </div>
            <div
              class="pointer d-flex justify-center primary--text"
              @click="assignSales(item.id)"
            >
              <v-icon color="primary" size="14"> mdi-pencil-outline </v-icon>
              <span class="executive ml-1"
                >{{
                  ["sales_head", "super_admin"].includes(user.user_type)
                    ? "Change Sales"
                    : "Unassign me"
                }}
              </span>
            </div>
          </div>
          <div v-else>
            <div
              class="pointer primary--text"
              @click="
                executiveModal({
                  id: item.id,
                  show: true,
                  type: 'assign_sales',
                })
              "
            >
              <v-icon color="primary" size="14"> mdi-pencil </v-icon>
              <span class="executive ml-1">Assign Sales </span>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            outlined
            color="primary"
            class="text-capitalize"
            height="32px"
            @click="clientDetail(item.id)"
          >
            <span>View</span>
          </v-btn>
          <v-menu offset-y min-width="150">
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                outlined
                color="primary"
                class="text-capitalize"
                height="32px"
                width="5px"
                min-width="5px"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list nav dense>
              <v-list-item
                v-for="i in actions"
                :key="i.key"
                link
                @click="onActionClicked(i.key, item.id)"
              >
                <v-list-item-title v-text="i.value"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>

    <follow-up-modal />
    <irrelevant-modal />
    <select-executive-modal @selected="onSelected" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

import FollowUpModal from "./followUpModal";
import IrrelevantModal from "./irrelevantModal";
import header from "@/common/clientManagementHeader/ClientManagementHeader";
import SelectExecutiveModal from "@/components/selectExecutiveModal";
export default {
  components: { FollowUpModal, IrrelevantModal, SelectExecutiveModal },
  data() {
    return {
      items: [],
      loading: false,

      page_total: 1,
      page_number: 1,
      total_count: 0,
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],
      page_count: 10,
    };
  },

  computed: {
    ...mapGetters({
      user: "getUser",
      search: "clientManagement/search",
      filters: "clientManagement/filters",
      activeTab: "clientManagement/activeTab",
      getExecutiveModal: "caseManagement/getExecutiveModal",
    }),

    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },

    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },

    headers() {
      switch (this.activeTab.key) {
        case "lead":
          return header.leads;
        case "assigned_to_me":
        case "assigned_to_sales_executive":
          return header.assigned;
        case "query_mail_sent":
          return header.query;
        case "follow_up":
          return header.follow_up_carry_forward;
        case "carry_forward":
          return header.follow_up_carry_forward;
        case "irrelevant":
          return header.irrelevant;
        case "client":
          return header.converted;
        default:
          return null;
      }
    },

    actions() {
      switch (this.activeTab.key) {
        case "lead":
          return [
            { value: "Carry Forward", key: "carry_forward" },
            {
              value: "Irrelevant Leads",
              key: "irrelevant_leads",
            },
            {
              value: "Send Query Mail",
              key: "query_mail_sent",
            },
            { value: "Add Case", key: "add_case" },
          ];

        case "assigned_to_me":
        case "assigned_to_sales_executive":
          return [
            { value: "Carry Forward", key: "carry_forward" },
            {
              value: "Irrelevant Leads",
              key: "irrelevant_leads",
            },
            {
              value: "Send Query Mail",
              key: "query_mail_sent",
            },
            { value: "Add Case", key: "add_case" },
          ];

        case "query_mail_sent":
          return [
            {
              value: "Irrelevant Leads",
              key: "irrelevant_leads",
            },
            {
              value: "Resend Query Mail",
              key: "query_mail_sent",
            },
            { value: "Add Case", key: "add_case" },
          ];

        case "follow_up":
          return [
            {
              value: "Irrelevant Leads",
              key: "irrelevant_leads",
            },
            {
              value: "Send Query Mail",
              key: "query_mail_sent",
            },
            { value: "Add Case", key: "add_case" },
          ];

        case "carry_forward":
          return [
            {
              value: "Irrelevant Leads",
              key: "irrelevant_leads",
            },
            {
              value: "Send Query Mail",
              key: "query_mail_sent",
            },
            { value: "Add Case", key: "add_case" },
          ];

        case "irrelevant":
          return [{ value: "Add Case", key: "add_case" }];

        case "client":
          return [{ value: "Add Case", key: "add_case" }];

        default:
          return [];
      }
    },
  },

  watch: {
    page_count() {
      this.getClientList();
    },

    page_number() {
      this.getClientList();
      this.updateRoute();
    },

    activeTab: {
      handler() {
        this.reloadData();
      },
      deep: true,
    },

    search() {
      this.debouncedReloadData();
    },

    filters: {
      handler() {
        this.reloadData();
        this.updateRoute();
      },
      deep: true,
    },
  },

  created() {
    const query = this.$route.query;

    if (query.page) {
      this.page_number = Number(query.page);
    }

    if (query.search) {
      this.setSearch(String(query.search));
    }

    if (query.executive) {
      this.setFilters({ executive: Number(query.executive) });
    }

    if (query.date) {
      this.setFilters({ date: [String(query.date)] });
    }

    if (query.start_date && query.end_date) {
      this.setFilters({
        date: [String(query.start_date), String(query.end_date)],
      });
    }

    if (query.source) {
      this.setFilters({ source: Number(query.source) });
    }

    if (query.reason) {
      this.setFilters({ reason: Number(query.reason) });
    }

    this.updateRoute();
  },

  mounted() {
    this.getClientList();
  },

  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
      setSearch: "clientManagement/setSearch",
      setFilters: "clientManagement/setFilters",
      toggleDeleteModal: "confirmation/toggleDeleteModal",
      reloadClientApi: "clientManagement/reloadClientApi",
      toggleFollowUpModal: "clientManagement/toggleFollowUpModal",
      toggleIrrelevantModal: "clientManagement/toggleIrrelevantModal",
      executiveModal: "caseManagement/executiveModal",
    }),

    debouncedReloadData: _.debounce(function () {
      this.reloadData();
      this.updateRoute();
    }, 1000),

    reloadData() {
      this.$emit("reloadData");
      this.getClientList();
    },

    updateRoute() {
      const query = {};

      if (this.search) {
        query.search = String(this.search);
      }

      if (this.page_number) {
        query.page = Number(this.page_number);
      }

      if (this.filters.executive) {
        query.executive = Number(this.filters.executive);
      }

      if (this.filters.date.length) {
        if (this.filters.date.length === 2) {
          query.start_date = String(this.filters.date[0]);
          query.end_date = String(this.filters.date[1]);
        } else {
          query.date = String(this.filters.date[0]);
        }
      }

      if (this.filters.source) {
        query.source = Number(this.filters.source);
      }

      if (this.filters.reason) {
        query.reason = Number(this.filters.reason);
      }

      this.$router
        .replace({
          path: this.$route.path,
          query: query,
        })
        .catch((error) => error);
    },

    clientDetail(id) {
      this.$router.push({
        name: "clientDetail",
        params: {
          id: id,
        },
      });
    },

    assignSales(client_id) {
      if (
        [this.$keys.SALES_HEAD, this.$keys.SUPER_ADMIN].includes(
          this.user.user_type
        )
      ) {
        this.executiveModal({
          id: client_id,
          show: true,
          type: "assign_sales",
        });
      } else {
        this.unAssignSales(client_id);
      }
    },

    unAssignSales(client_id) {
      let data = {
        client: client_id,
      };

      const successHandler = (res) => {
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.reloadData();
      };

      this.$request(this.$keys.POST, this.$urls.client.unAssignSales, {
        data: data,
        onSuccess: successHandler,
      });
    },

    getClientList() {
      this.items = [];
      this.loading = true;

      const onSuccess = (res) => {
        this.page_total = res.data.page_info.page_total;
        this.page_number = res.data.page_info.page_number;
        this.total_count = res.data.page_info.total_count;
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
        client_status: this.activeTab.key,
        search: this.search,
        executive_id: this.filters.executive,
        source_id: this.filters.source,
        reason_id: this.filters.reason,
      };

      if (this.filters.date) {
        if (this.filters.date.length === 2) {
          params["start_date"] = this.filters.date[0];
          params["end_date"] = this.filters.date[1];
        } else {
          params["date"] = this.filters.date[0];
        }
      }

      this.$request(this.$keys.GET, this.$urls.client.list, {
        params,
        onSuccess,
        onFinally,
        cancel: true,
      });
    },

    onActionClicked(action, client_id) {
      switch (action) {
        case "carry_forward":
          this.toggleFollowUpModal({
            show: true,
            id: client_id,
          });
          break;
        case "irrelevant_leads":
          this.toggleIrrelevantModal({
            show: true,
            id: client_id,
          });
          break;
        case "query_mail_sent":
          this.sendQueryMail(client_id);
          break;
        case "add_case":
          this.$router.push({
            name: "caseAdd",
            query: { client: client_id },
          });
          break;
        default:
          break;
      }
    },

    sendQueryMail(client_id) {
      const data = {
        client: client_id,
      };

      const onSuccess = (res) => {
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.reloadData();
      };

      this.$request(this.$keys.POST, this.$urls.client.sendQueryMail, {
        data,
        onSuccess,
      });
    },

    onSelected(selected_id) {
      const data = {
        client: this.getExecutiveModal.id,
        sales: selected_id,
      };

      const onSuccess = () => {
        this.reloadData();
        this.executiveModal({
          id: "",
          show: false,
          type: "",
        });
      };

      this.$request(this.$keys.POST, this.$urls.client.assignSales, {
        data,
        onSuccess,
      });
    },

    onItemDoubleClick(event, { item }) {
      this.clientDetail(item.id);
    },
  },
};
</script>