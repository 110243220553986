<template>
  <div>
    <div class="d-flex justify-space-between">
      <div class="d-flex justify-start" style="gap: 20px">
        <v-text-field
          v-model="search"
          clearable
          outlined
          dense
          placeholder="Search name, email, or etc."
          prepend-inner-icon="mdi-magnify"
          color="primary"
        ></v-text-field>

        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="400px"
          offset-y
          nudge-top="20"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-text-field
              :value="dateRangeText"
              placeholder="Filter by Date Range"
              v-bind="attrs"
              outlined
              dense
              append-icon="mdi-calendar"
              v-on="on"
              clearable
              readonly
              @click:clear="dateRange = []"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateRange"
            color="primary"
            range
            no-title
            :max="maxDate"
            @input="onDateSelected"
          ></v-date-picker>
        </v-menu>

        <v-select
          v-if="[$keys.SALES_HEAD, $keys.ACCOUNTS, $keys.SUPER_ADMIN].includes(user.user_type)"
          :menu-props="{ bottom: true, offsetY: true }"
          append-icon="mdi-chevron-down"
          v-model="executive"
          clearable
          :items="executive_list"
          item-text="name"
          item-value="id"
          placeholder="Filter by Sales"
          outlined
          dense
        ></v-select>

        <v-select
          :menu-props="{ bottom: true, offsetY: true }"
          append-icon="mdi-chevron-down"
          v-model="source"
          clearable
          :items="source_list"
          item-text="name"
          item-value="id"
          placeholder="Filter by Source"
          outlined
          dense
        ></v-select>

        <v-select
          v-if="
            ['follow_up', 'carry_forward', 'irrelevant'].includes(
              getActiveTab.key
            )
          "
          :menu-props="{ bottom: true, offsetY: true }"
          append-icon="mdi-chevron-down"
          v-model="reason"
          :items="reason_list"
          item-text="title"
          item-value="id"
          clearable
          outlined
          dense
          placeholder="Filter by Reason"
        ></v-select>
      </div>

      <div class="d-flex justify-end" style="gap: 20px">
        <v-btn
          @click="LeadsModal({ show: true, id: null, type: 'add' })"
          class="text-capitalize px-7"
          color="primary"
          height="40px"
          outlined
        >
          <v-icon size="16">mdi-plus</v-icon><span>Add Lead</span>
        </v-btn>
      </div>
    </div>

    <v-sheet elevation="3" class="rounded-b-0">
      <v-tabs
        fixed-tabs
        height="125px"
        next-icon="mdi-chevron-right-circle"
        prev-icon="mdi-chevron-left-circle"
        v-model="activeTab"
        show-arrows
      >
        <v-tab
          v-for="(item, index) in tabs"
          :key="index + item.key"
          class="tab-width"
        >
          <div>
            <div class="tab-total">
              {{ item.value }}
            </div>
            <div class="tab-name pt-2">
              {{ item.label }}
            </div>
          </div>
        </v-tab>
      </v-tabs>
    </v-sheet>

    <v-divider />

    <clientManagementTable ref="dataTable" @reloadData="getClientCount" />
    <add-leads-modal @submitted="reloadData" />
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import AddLeadsModal from "./addLeadsModal.vue";
import clientManagementTable from "./clientManagementTable.vue";

export default {
  components: { AddLeadsModal, clientManagementTable },
  data() {
    return {
      dateMenu: false,
      tabCounts: {
        lead: 0,
        assigned_to_me: 0,
        assigned_to_sales_executive: 0,
        query_mail_sent: 0,
        follow_up: 0,
        carry_forward: 0,
        irrelevant: 0,
        client: 0,
      },
      reason_list: [],
      source_list: [],
      executive_list: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      getFilters: "clientManagement/filters",
      getSearch: "clientManagement/search",
      getActiveTab: "clientManagement/activeTab",
    }),

    activeTab: {
      get() {
        return this.getActiveTab.index;
      },
      set(value) {
        this.setActiveTab({ index: value, key: this.tabs[value].key });
      },
    },

    maxDate() {
      const today = this.$moment();
      return today.format("YYYY-MM-DD");
    },

    dateRangeText() {
      const _filter_by_date = this.dateRange.map((item) =>
        this.$moment(item).format("DD-MM-YYYY")
      );
      return _filter_by_date.join(" ~ ");
    },

    search: {
      get() {
        return this.getSearch;
      },
      set(value) {
        this.setSearch(value);
      },
    },

    dateRange: {
      get() {
        return this.getFilters.date;
      },
      set(value) {
        this.setFilters({ date: value });
      },
    },

    source: {
      get() {
        return this.getFilters.source;
      },
      set(value) {
        this.setFilters({ source: value });
      },
    },

    reason: {
      get() {
        return this.getFilters.reason;
      },
      set(value) {
        this.setFilters({ reason: value });
      },
    },

    executive: {
      get() {
        return this.getFilters.executive;
      },
      set(value) {
        this.setFilters({ executive: value });
      },
    },

    tabs() {
      let _tabs = [
        {
          label: `Leads`,
          key: "lead",
          value: this.tabCounts.lead,
        },
        {
          label: `Assigned to me`,
          key: "assigned_to_me",
          value: this.tabCounts.assigned_to_me,
        },
        {
          label: `Assigned to Sales`,
          key: "assigned_to_sales_executive",
          value: this.tabCounts.assigned_to_sales_executive,
        },
        {
          label: `Query Mail Sent Leads`,
          key: "query_mail_sent",
          value: this.tabCounts.query_mail_sent,
        },
        {
          label: `Follow-up Leads`,
          key: "follow_up",
          value: this.tabCounts.follow_up,
        },
        {
          label: `Carry-forward Leads`,
          key: "carry_forward",
          value: this.tabCounts.carry_forward,
        },
        {
          label: `Irrelevant Leads`,
          key: "irrelevant",
          value: this.tabCounts.irrelevant,
        },
        {
          label: `Clients`,
          key: "client",
          value: this.tabCounts.client,
        },
      ];

      return _.filter(_tabs, (tab) => {
        if (
          !(
            ["lead", "assigned_to_sales_executive"].includes(tab.key) &&
            ["sales_executive"].includes(this.user.user_type)
          )
        )
          return tab;
      });
    },
  },

  watch: {
    activeTab() {
      this.reason = null;

      if (
        ["follow_up", "irrelevant", "carry_forward"].includes(
          this.getActiveTab.key
        )
      ) {
        this.getReasonList(this.getActiveTab.key);
      }
    },
  },

  mounted() {
    this.getClientCount();
    this.getSourceList();
    this.getExecutiveList();
    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  beforeDestroy() {
    this.$root.$off("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      setSearch: "clientManagement/setSearch",
      setFilters: "clientManagement/setFilters",
      setActiveTab: "clientManagement/setActiveTab",
      LeadsModal: "clientManagement/LeadsModal",
    }),

    navClickHandler(navKey) {
      if (navKey === "client_management") {
        this.reloadData();
        this.$refs.dataTable.updateRoute();
      }
    },

    reloadData() {
      this.getClientCount();
      this.$refs.dataTable.getClientList();
    },

    onDateSelected() {
      if (this.dateRange.length === 2) this.dateMenu = false;
    },

    getExecutiveList() {
      const params = new URLSearchParams();
      params.append("role", this.$keys.SALES_HEAD);
      params.append("role", this.$keys.SALES_EXECUTIVE);
      params.append("apply_pagination", false);

      const onSuccess = (res) => {
        this.executive_list = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.team.list, {
        params,
        onSuccess,
      });
    },

    getReasonList(reason_type) {
      const onSuccess = (res) => {
        this.reason_list = res.data.data;
      };

      const params = {
        reason_type: reason_type,
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.master.reason.list, {
        params,
        onSuccess,
      });
    },

    getSourceList() {
      const onSuccess = (res) => {
        this.source_list = res.data.data;
      };

      const params = {
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.master.source.list, {
        params,
        onSuccess,
      });
    },

    getClientCount() {
      const onSuccess = (res) => {
        this.$_.forEach(this.tabCounts, (value, key) => {
          this.tabCounts[key] = res.data.data[key];
        });
      };

      const params = {
        search: this.search,
        executive_id: this.executive,
        source_id: this.source,
        reason_id: this.reason,
      };

      if (this.dateRange) {
        if (this.dateRange.length === 2) {
          params["start_date"] = this.dateRange[0];
          params["end_date"] = this.dateRange[1];
        } else {
          params["date"] = this.dateRange[0];
        }
      }

      return this.$request(this.$keys.GET, this.$urls.client.count, {
        params,
        onSuccess,
      });
    },
  },
};
</script>
<style scoped>
.tab-total {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}
.tab-name {
  word-wrap: break-word;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
</style>
